import { template as template_0c600a2c297041ccaa1f707570fcb5aa } from "@ember/template-compiler";
const SidebarSectionMessage = template_0c600a2c297041ccaa1f707570fcb5aa(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
