import { template as template_8c5ff1bb808e4ee4bcd6f193ecde4e80 } from "@ember/template-compiler";
const FKLabel = template_8c5ff1bb808e4ee4bcd6f193ecde4e80(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
