import { template as template_ba57fd1b714b453d887cc7625e9c18f7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ba57fd1b714b453d887cc7625e9c18f7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
