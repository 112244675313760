import { template as template_ebecc056199640d59112ccae271eef38 } from "@ember/template-compiler";
const FKText = template_ebecc056199640d59112ccae271eef38(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
